<template>

  <ul id="dropdown1" class="dropdown-content">
    <li><a href="/#/cursosec">Cursos</a></li>
    <li><a href="/#/diseno">Diseño de cursos</a></li>
    <li><a href="/#/asesoria">Asesoría tecnológica</a></li>
  </ul>
  <ul id="dropdown2" class="dropdown-content">
    <li><a href="/#/cursosec">Cursos</a></li>
    <li><a href="/#/diseno">Diseño de cursos</a></li>
    <li><a href="/#/asesoria">Asesoría tecnológica</a></li>
  </ul>
  <div class="">
  <nav>
    <div class="nav-wrapper brown lighten-4">
    <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
    <a id="logo-container" href="#" class="brand-logo left  brown-text" >
        <img src="images/logo.svg" alt="logo contecedu" style="max-height: 32px;">
        ConTecEdu
    </a> 
      <ul class="right hide-on-med-and-down brown-text">
        <li><router-link to="/">Inicio</router-link> </li>
        <li><router-link to="/about">¿Quienes somos?</router-link> </li>
        <li><a class="dropdown-trigger" href="#" data-target="dropdown1" >Servicios<i class="material-icons right">arrow_drop_down</i></a></li>
        <li><router-link to="/contacto">Contacto</router-link></li>
      </ul>
      <ul class="sidenav" id="mobile-demo">
        <li><router-link to="/">Inicio</router-link> </li>
        <li><router-link to="/about">¿Quienes somos?</router-link> </li>
        <li><a class="dropdown-trigger" href="#" data-target="dropdown2" >Servicios<i class="material-icons right">arrow_drop_down</i></a></li>
        <li><router-link to="/contacto">Contacto</router-link></li>
      </ul>
    </div>
  </nav>
  </div>
  <transition name="fade">
      <div id="pagetop" class="fixed right-0 bottom-0" v-show="scY > 300" @click="toTop">
        <a onclick="window.scrollTo(0,0);" class="btn-floating btn-large waves-effect waves-light amber accent-4 btn-subir "><i class="material-icons brown-text">arrow_upward</i></a>
      </div>
  </transition>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.btn-subir {
    position: fixed;
    bottom: 2vh;
    left: 2vw;
}


/*template contecedu*/
nav .brand-logo {
    color: #63452c !important;
    position:inherit !important;
}
nav ul a {
    color: #63452c !important;
}
.teal-text.text-lighten-2 {
    color: #63452c !important;
}   
.contenedor-amarillo{
    background-color:#e5a50acc;
    padding:1rem;
} 
nav ul li.active {
    background-color:#e5a50a;
}

nav a {
    color: #63452c !important;
}

.img-fancyblog{
  box-shadow: 10px 10px 58px -2px rgba(0,0,0,0.54);
  -webkit-box-shadow: 10px 10px 58px -2px rgba(0,0,0,0.54);
  -moz-box-shadow: 10px 10px 58px -2px rgba(0,0,0,0.54);
  border-radius: 25%;
  width: 100%;
}
.frame-for-imagefancyblog{
  display: flex;
  align-items: center;
  min-height: 400px!important;
}

.rounded-icon{
    padding: 0.8rem;
    border-radius: 30%;
}


.darkgoldenrod{
    background-color: darkgoldenrod;
}

.darkbrown-text {
    color: #503830 !important;
}

.rounded-icon-darkcyan{
    background-color: darkcyan;
    color: white;
    padding: 0.8rem;
    border-radius: 50%;
}

.rounded-icon-darkorange{
    background-color: darkorange;
    color: white;
    padding: 0.8rem;
    border-radius: 50%;
}

.bullet-flecha{
  list-style-type: disclosure-closed!important;
}

.bullet-disco{
  list-style-type:disc!important;
}
h1 {
    font-size: 3rem !important;
}

strong{
  font-weight: bolder!important;
}
</style>

<script>
import M from 'materialize-css'
//import axios from 'axios'

export default{
  name: 'App',
  //data(){
    //axios.get('http://localhost/registros/prospecto')
  //},
  data() {
      return {
        scTimer: 0,
        scY: 0,
      }
    },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  },
  methods:{
      /*M.Dropdown.init(elems);*/
      saludar(){

      },
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
  }
}

        document.addEventListener('DOMContentLoaded', function() {
          var elems_dropsdowns = document.querySelectorAll('.dropdown-trigger');
          var elems_sidenav = document.querySelectorAll('.sidenav');
          var elems_select = document.querySelectorAll('select');
//          var elems_collapsibles = document.querySelectorAll('.collapsible');
          M.Dropdown.init(elems_dropsdowns);
          M.Sidenav.init(elems_sidenav);
          M.FormSelect.init(elems_select);
//          M.Collapsible.init(elems_collapsibles, {accordion: false});
        });

</script>