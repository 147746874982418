import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: () => import('../views/ServicesView.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import('../views/ContactoView.vue')
  },
  {
    path: '/ec0050',
    name: 'ec0050',
    component: () => import('../views/ec0050View.vue')
  },
  {
    path: '/cursosec',
    name: 'cursosec',
    component: () => import('../views/CursosEC.vue')
  },
  {
    path: '/preregistrocursos',
    name: 'PreRegistroCursos',
    component: () => import('../views/PreRegistroCursos.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
